.profilecontainer{padding: 0px 0px 40px 0px;}
.profilecontainer .prpcontainer{float: left; width: 100%; position: relative; padding: 10px; background: #fdf9f8;}
.profilecontainer .profileleftpanel{float: left; width: 100%; background: linear-gradient(0deg, #00dab9, #00cacf), #00c0db; padding: 30px 0px 20px 0px; -webkit-border-radius: 0px; border-radius: 0px; -webkit-box-shadow: none; box-shadow: none;}
.profilecontainer .profileleftpanel .usercolumn{position: relative; float: left; width: 100%; background: transparent; padding: 0px 25px 18px 25px; text-align: center;}
.profilecontainer .profileleftpanel .usercolumn:after{content: ""; border-bottom: 1px solid #AAAD83; position: absolute; left: 25px; right: 25px; bottom: 0px;}
.profilecontainer .profileleftpanel .usercolumn .userimg{display: inline-block; text-align: center; position: relative; margin: 0px 0px 15px 0px;}
.profilecontainer .profileleftpanel .usercolumn .userimg img{width: 130px; height: 130px; -webkit-border-radius: 50%; border-radius: 50%; border: 1px solid #fff; padding: 8px;}
.profilecontainer .profileleftpanel .usercolumn .userimg .useruploadimg{position: absolute; right: 0px; bottom: 8px; width: 30px; height: 30px; overflow: hidden; line-height: 30px; font-size: 15px; background: #F3F3F3; color: #AB7F5A; -webkit-border-radius: 50%; border-radius: 50%;}
.profilecontainer .profileleftpanel .usercolumn .userimg .useruploadimg input[type="file" i]{position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; width: 100%; opacity: 0;}

.profilecontainer .profileleftpanel .usercolumn h3{color: #fff; text-align: center; margin: 0px 0px 10px 0px; text-transform: uppercase; font-size: 22px; font-weight: 500; padding: 0px;}
.profilecontainer .profileleftpanel .usercolumn p{color: #fff; text-align: center; margin: 0px 0px 5px 0px; font-size: 14px; padding: 0px; font-weight: 300;}
.profilecontainer .profileleftpanel .usercolumn p a{color: #fff; font-size: 16px; text-decoration: none; font-weight: 500;}
.profilecontainer .profileleftpanel .usernavlist{float: left; width: 100%; margin: 0px; padding: 15px 0px 15px 0px;}
.profilecontainer .profileleftpanel .usernavlist ul{float: left; width: 100%; margin: 0px; padding: 0px;}
.profilecontainer .profileleftpanel .usernavlist ul li{float: left; width: 100%; margin: 0px; padding: 0px 0px; position: relative; list-style: none; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.profilecontainer .profileleftpanel .usernavlist ul li a{float: left; width: 100%; font-size: 15px; margin: 0px; padding: 12px 10px 12px 68px; color: #000; text-decoration: none; position: relative; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.profilecontainer .profileleftpanel .usernavlist ul li a i{position: absolute; left: 25px; top: 50%; color: #000; width: 22px; height: 34px; text-align: center; line-height: 33px; font-size: 1.2em; -webkit-border-radius: 50%; border-radius: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.profilecontainer .profileleftpanel .usernavlist ul li:hover a{color: #ffffff;}
.profilecontainer .profileleftpanel .usernavlist ul li:hover a i{color: #ffffff;}
.profilecontainer .profileleftpanel .usernavlist ul li a.active{color: #fff;}
.profilecontainer .profileleftpanel .usernavlist ul li a.active i{color: #ffffff;}
.profilecontainer .profileleftpanel .usernavlist ul li.logout{margin-top: 15px; padding-top: 5px;}
.profilecontainer .profileleftpanel .usernavlist ul li.logout a{background: transparent; color: #43bfff;}
.profilecontainer .profileleftpanel .usernavlist ul li.logout:before{content: ""; position: absolute; top: 0px; left: 20px; right: 20px; border-top: 1px solid #fff;}
.profilecontainer .rightprofile{margin-top: 25px; float: left; width: 100%; padding: 20px 10px; position: relative;}
.profilecontainer .rightprofile h2{color: #1E1E1E; font-size: 22px; font-weight: 500; text-transform: capitalize; text-align: left; margin: 0px 0px 15px 0px; padding: 0px; position: relative;}
.profilecontainer .formfloating label, input{margin-bottom: 10px;}
.profilecontainer .formfloating input:focus{box-shadow: none;}
.profilecontainer .rightprofile p{color: #32353c; text-align: left; margin: 0px 0px 30px 0px; font-size: 15px; padding: 0px; font-weight: 400;}
.profilecontainer .rightprofile .dl-horizontal hr{margin-top: 12px; margin-bottom: 12px; border: 0; border-top: 1px solid rgba(0, 0, 0, 0.31);}
.profilecontainer .dashboardbox{float: left; width: 100%; background: #fdf4ea; text-decoration: none; padding: 20px 20px; height: 100%; border: 1px solid #3A9DF8; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; align-items: center; text-align: left; -webkit-border-radius: 7px; border-radius: 7px; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.profilecontainer .dashboardbox .dashboardboxinner{display: block; text-align: left;}
.profilecontainer .dashboardbox .dashboardboxinner .dashicon{display: table-cell; vertical-align: middle; padding-right: 15px;}
.profilecontainer .dashboardbox .dashboardboxinner .dashcontent{display: table-cell; vertical-align: middle; width: 100%;}
.profilecontainer .dashboardbox i{display: inline-block; width: 60px; height: 60px; color: #fff; font-size: 2em; background: #3A9DF8; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; justify-content: center; align-items: center; text-align: center; -webkit-border-radius: 8px 20px 8px 20px; border-radius: 8px 20px 8px 20px;}
.profilecontainer .dashboardbox h3{color: #3A9DF8; font-size: 16px; font-weight: 600; margin: 0px;}
.profilecontainer .dashboardbox:hover{text-decoration: none;}

.dashboardbtx{float: left; width: 100%; height: 100%; background: linear-gradient(121.16deg, #AB7F5A 18.31%, #CACDA5 73.06%, #CACDA5 96.73%); background-size: cover; background-repeat: no-repeat; background-position: center right; padding: 30px; -webkit-border-radius: 8px; border-radius: 8px;}
.dashboardbtx .dashicon{display: table-cell; vertical-align: middle; padding-right: 7px;}
.dashboardbtx .dashicon i{float: left; width: 60px; height: 60px; position: relative; font-size: 28px; background: #fff; color: #39378A; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; justify-content: center; align-items: center; text-align: center; -webkit-border-radius: 50%; border-radius: 50%;}
.dashboardbtx .dashicon i:after{content: ""; position: absolute; left: -7px; top: -7px; bottom: -7px; right: -7px; border: 1px solid #fff; -webkit-border-radius: 50%; border-radius: 50%;}
.dashboardbtx .dashctcontent{display: table-cell; vertical-align: middle; width: 100%; padding-right: 20px;}
.dashboardbtx .dashctcontent h3{color: #1E1E1E; font-size: 20px; font-weight: 600; margin: 0px; padding: 0px; text-align: left;}
.dashboardbtx .dashctcontent p{color: #1E1E1E; font-size: 15px; font-weight: 400; margin: 0px 0px 4px 0px; padding: 0px; text-align: left;}
.dashboardbtx .dashicon i img{max-width: 27px;}
.profilecontainer .rightprofile .dl-horizontal{font-size: 15px;}
.profilecontainer .rightprofile .dl-horizontal dt strong{font-weight: 600;}
dl{margin-top: 0; margin-bottom: 20px;}


.profilecontainer .rightprofile .form-group .formfloating .choices__inner{height: 44px; border: 1px solid #CBC9D9; padding: 7px 15px; color: #6D6D6D; background: #fff; font-size: 14px; font-weight: 400; -webkit-box-shadow: none; box-shadow: none; -webkit-border-radius: 5px; border-radius: 5px;}
.profilecontainer .rightprofile .form-group .formfloating .choices__inner .choices__input{color: #6D6D6D; font-size: 13px; background: none;}
.profilecontainer .rightprofile .form-group .formfloating .choices__inner .choices__list--multiple .choices__item{padding: 4px 10px; margin: 3px 5px 3px 0px; font-size: 11px; background: #39378A; border-color: #39378A;}
.profilecontainer .rightprofile .choices[data-type*=select-multiple] .choices__button, .profilecontainer .rightprofile .choices[data-type*=text] .choices__button{border-color: #6664b2;}
.profilecontainer .rightprofile .form-group .choices__list--dropdown{border-color: #CBC9D9; -webkit-border-radius: 5px; border-radius: 5px;}
.profilecontainer .rightprofile .form-group .choices__list--dropdown .choices__item--selectable.is-highlighted{background: #39378A; color: #fff;}
.profilecontainer .rightprofile .table{border: 1px solid #D3D3D3;}
.profilecontainer .rightprofile .table thead tr th{background: #E9FFFF; vertical-align: middle; color: #1E1E1E; font-size: 14px; font-weight: 600; padding: 10px 15px; border-color: #F0F0FF; border-top: 1px solid #D3D3D3; border-bottom: 1px solid #D3D3D3; border-bottom-width: 1px;text-align: center;}
.profilecontainer .rightprofile .table thead tr th:first-child{border-left: #D3D3D3;}
.profilecontainer .rightprofile .table thead tr th:last-child{border-right: #D3D3D3;}
.profilecontainer .rightprofile .table tbody tr td{text-align: center; font-size: 14px; color: #000000; font-weight: 400; padding: 10px 15px; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;vertical-align: middle;}
.profilecontainer .rightprofile .table tbody tr td:first-child{border-left: 1px solid #D3D3D3;}
.profilecontainer .rightprofile .table tbody tr td:last-child{border-right: 1px solid #D3D3D3;}
.profilecontainer .rightprofile .table tfoot tr td{ font-size: 14px; color: #000000; font-weight: 400; padding: 10px 15px; border-color: #fff; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.profilecontainer .rightprofile .table tfoot tr td:first-child{border-left: 1px solid #D3D3D3;}
.profilecontainer .rightprofile .table tfoot tr td:last-child{border-right: 1px solid #D3D3D3;}
.profilecontainer .rightprofile .table tbody tr td img{max-width: 58px; max-height: 100%;}
.profilecontainer .rightprofile .table tbody tr td p{font-size: 14px; color: #000000; font-weight: 400; margin: 3px 0px; width: 100%;}
.profilecontainer .rightprofile .table tbody tr td .badge{display: inline-block; background: #000; color: #fff; border: 1px solid #000; font-size: 12px; text-transform: capitalize; padding: 6px 10px; font-weight: 400; min-width: 100px; -webkit-border-radius: 50px; border-radius: 50px;}
.profilecontainer .rightprofile .table tbody tr td .badge.text-bg-danger{border-color: #DF0021 !important; color: #DF0021 !important; background: #FFE0E5 !important;}
.profilecontainer .rightprofile .table tbody tr td .badge.text-bg-info{border-color: #00D3C7 !important; color: #00D3C7 !important; background: #DCFFFD !important;}
.profilecontainer .rightprofile .table tbody tr td .badge.text-bg-warning{border-color: #C3A400 !important; color: #C3A400 !important; background: #FFF8D2 !important;}
.profilecontainer .rightprofile .table tbody tr td .badge.text-bg-success{border-color: #5DA326 !important; color: #5DA326 !important; background: #ECFFDD !important;}
/* .profilecontainer .rightprofile .table tbody tr td .btn{background: none; font-size: 18px; color: #AB7F5A; width: 28px; height: 28px; text-align: center; line-height: 28px; -webkit-border-radius: 3px; border-radius: 3px; padding: 0px;}
.profilecontainer .rightprofile .table tbody tr td .btn i{font-size: inherit;}
.profilecontainer .rightprofile .table tbody tr td .btn:hover{border-color: #CACDA5; background: #CACDA5; color: #000;}
.profilecontainer .rightprofile .table tbody tr td .btn.btn-danger{background: none; font-size: 18px; color: #DF0021; border: 1px solid transparent; width: 28px; height: 28px; text-align: center; line-height: 28px; -webkit-border-radius: 3px; border-radius: 3px; padding: 0px;}
.profilecontainer .rightprofile .table tbody tr td .btn.btn-danger:hover{background: #FFE0E5 !important; border-color: #DF0021 !important; color: #DF0021 !important;} */
.profilecontainer .rightprofile .table tbody tr td .actionbtns{white-space: nowrap;}
.profilecontainer .rightprofile .table tbody tr td .form-group{margin: 0px; display: block;}
.profilecontainer .rightprofile .table tbody tr td .form-control{height: 40px; width: 100%; border: 1px solid #CBC9D9; padding: 7px 15px; color: #6D6D6D; background: #fff; font-size: 13px; font-weight: 400; -webkit-box-shadow: none; box-shadow: none; -webkit-border-radius: 5px; border-radius: 5px;}
.profilecontainer .rightprofile .table tbody tr td select.form-control{background: url(../images/dummy.png) no-repeat right center; -webkit-appearance: none; -moz-appearance: none; -o-appearance: none;}
.profilecontainer .rightprofile .btnsm{border-color: #39378A; background: none; font-size: 16px; color: #39378A; width: 28px; height: 28px; text-align: center; line-height: 28px; -webkit-border-radius: 3px; border-radius: 3px; padding: 0px;}
.profilecontainer .rightprofile .btnsm i{font-size: inherit;}
.profilecontainer .rightprofile .btnsm:hover{border-color: #39378A; background: #39378A; color: #fff;}
.profilecontainer .rightprofile .btn-danger.btnsm{color: #DF0021; border-color: #DF0021;}
.profilecontainer .rightprofile .btn-danger.btnsm:hover{color: #fff; border-color: #DF0021; background: #DF0021;}
.profilecontainer .rightprofile .append-buttons{padding-top: 0px; margin-top: -5px; text-align: right;}

.profilecontainer .paginationright{margin-top: 15px; margin-bottom: 10px;}
.profilecontainer .paginationright ul li.active{color: #fff; border-color: #39378A; background: #39378A;}

.theme-primary-btn{background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%); display: inline-block; font-size: 15px; text-decoration: none; padding: 10px 30px; border: none; outline: none; color: #fff; -webkit-border-radius: 40px; border-radius: 40px; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.theme-primary-btn span{display: inline-block; vertical-align: middle; padding-right: 10px;}
.theme-primary-btn i{display: inline-block; vertical-align: middle; font-size: 80%;}
.theme-primary-btn:hover{color: #fff;transform: scale(1.1);}
.theme-primary-btn.borderradius5{-webkit-border-radius: 5px; border-radius: 5px; width: 100%; margin-bottom: 25px;}

.tabbtx{display: table; position: relative; width: 100%; margin-bottom: 0px;}
.tabbtx .nav-tabs{border: none;}
.tabbtx .nav-tabs .nav-item{margin: 0px 0px 0px 0px;}
.tabbtx .nav-tabs .nav-item .nav-link{position: relative; background: #F5F4FF; border: 1px solid #e3deef; border-right: none; color: #3C3E48; padding: 10px 30px; font-size: 14px; font-weight: 400; letter-spacing: 0px; -webkit-border-radius: 0px; border-radius: 0px; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.tabbtx .nav-tabs .nav-item:last-child .nav-link{border-right: 1px solid #e3deef;}
.tabbtx .nav-tabs .nav-item .nav-link:after{content: ""; position: absolute; top: -1px; left: 0px; z-index: 0; background: #DF0021; height: 3px; width: 0px; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
.tabbtx .nav-tabs .nav-item .nav-link span{position: relative; z-index: 1; display: block; -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
.tabbtx .nav-tabs .nav-item .nav-link.active{background: #fff; border-bottom-color: #fff;}
.tabbtx .nav-tabs .nav-item .nav-link.active:after{background: #DF0021; width: 100%;}
.tabbtx .nav-tabs .nav-item .nav-link.active span{color: #DF0021;}
.tabbtx .nav-tabs .nav-item .nav-link:hover{background: #fff; border-bottom-color: #fff;}
.tabbtx .nav-tabs .nav-item .nav-link:hover:after{background: #DF0021; width: 100%;}
.tabbtx .nav-tabs .nav-item .nav-link:hover span{color: #DF0021;}
.profilecontainer .tab-content{border: 1px solid #e3deef;}
.profilecontainer .tab-content .tab-pane{padding: 25px 20px;}

.profilecontainer .accountrightbody{float: left; width: 100%; padding: 15px 15px 9px 15px; border: 1px solid #e8e8e8; border-radius: 2px;}
.profilecontainer .accountrightbody + .accountrightbody{margin-top: 25px;}
.profilecontainer .accountinnerheading{float: left; position: relative; width: 100%; padding: 0px 0px 10px 0px; margin-bottom: 20px; border-bottom: 1px solid #ebebeb; text-transform: uppercase; color: #738585; text-align: left; font-size: 14px; font-weight: 500;}
.profilecontainer .addaddresspanel{float: left; width: 100%;}
.profilecontainer .addaddresspanel .no-previous-address{float: left; width: 100%; text-align: center; padding: 30px 0px 20px 0px;}
.profilecontainer .addaddresspanel .no-previous-address p{text-align: center; color: #2d3c35; font-size: 14px; font-weight: 400; letter-spacing: 1px;}
.profilecontainer .addaddresspanel .addaddressbtn{float: left; width: 100%; margin: 10px 0px 20px 0px;}
.profilecontainer .addaddresspanel .addaddressbtn a{float: left; width: 100%; text-decoration: none; border: 1px solid #c5c5c5; padding: 8px 15px; text-align: center; border-radius: 4px; color: #afafaf; font-size: 15px; text-transform: uppercase;}
.profilecontainer .addaddresspanel .addaddressbtn a i{width: 30px; height: 30px; border: 1px solid #afafaf; text-align: center; line-height: 30px; margin-right: 7px; font-weight: 300; border-radius: 50%;}
.profilecontainer .accountinnerheading .backbtn{position: absolute; right: 0px; top: 0px; text-decoration: none; text-transform: uppercase; text-align: center; font-size: 13px; color: #0a000d; outline: none;}
.profilecontainer .accountinnerheading .backbtn i{padding-right: 6px;}
.profilecontainer .accountinnerform .orderdatepanel{float: left; width: 100%;}
.profilecontainer .accountinnerform .orderdatepanel p{color: #34246b; text-align: left; margin: 0px 0px 10px 0px; font-weight: 400; font-size: 14px;}
.profilecontainer .accountinnerform .dl-horizontal span.rightcolumn{float: right; padding: 0px 10px;}
.profilecontainer .accountinnerform .orderdatepanel .dl-horizontal .cancelled{color: #ef1613; display: block;}
.profilecontainer .accountinnerform .orderdatepanel .dl-horizontal .pending{color: #11328e; display: none;}
.profilecontainer .accountinnerform .orderdatepanel .dl-horizontal .delivered{color: #1b861c; display: none;}
.profilecontainer .accountinnerform .orderdatepanel .dl-horizontal dd{font-weight: 600;}
.profilecontainer .itempanel{float: left; width: 100%;}
.profilecontainer .itempanel .itempanelheading{float: left; position: relative; width: 100%; padding: 0px 0px 7px 0px; margin-bottom: 20px; border-bottom: 1px solid #ebebeb; text-transform: uppercase; color: #5d5d5d; text-align: left; font-size: 13px; font-weight: 600;}
.profilecontainer .itempanel .itempanelheading .total{float: right; color: #5d5d5d; font-size: 13px; font-weight: 600; text-transform: uppercase;}
.profilecontainer .itempanel .productitempanel{float: left; width: 100%;}
.profilecontainer .itempanel .productitempanel .productitem{float: left; width: 100%;}
.profilecontainer .itempanel .productitempanel .productmedia{display: table-cell; vertical-align: top;}
.profilecontainer .itempanel .productitempanel .productmedia img{width: 180px; max-height: 100%; border: 1px solid #ddd;}
.profilecontainer .itempanel .productitempanel .productdesc{display: table-cell; vertical-align: top; width: 100%; padding: 0px 20px;}
.profilecontainer .itempanel .productitempanel .productdesc .producttitle{color: #5d5d5d; text-align: left; font-size: 14px; font-weight: 600; float: left; width: 100%; margin-bottom: 10px;}
.profilecontainer .itempanel .productitempanel .productdesc .qtypanel{float: left; width: 100%;}
.profilecontainer .itempanel .productitempanel .productdesc .qtypanel .ctiinner{float: left; color: #5d5d5d; font-size: 14px; font-weight: 500; margin: 0px 30px 10px 0px;}
.profilecontainer .itempanel .productitempanel .productdesc .qtypanel .ctiinner span{color: #999; padding-right: 6px;}
.profilecontainer .itempanel .productitempanel .productdesc .statuspanel{float: left; width: 100%; margin-top: 10px;}
.profilecontainer .itempanel .productitempanel .productdesc .statuspanel .stinner{float: left; width: 100%; color: #5d5d5d; font-size: 14px; font-weight: 500; margin: 0px 30px 10px 0px;}
.profilecontainer .itempanel .productitempanel .productdesc .statuspanel .stinner span{color: #999; padding-right: 6px;}
.profilecontainer .itempanel .productitempanel .productdesc .statuspanel .stinner .cancelled{color: #ef1613;}
.profilecontainer .itempanel .productitempanel .totalprice{color: #000; font-weight: 600; font-size: 15px; display: block; white-space: nowrap;}
.profilecontainer .itempanel .productitempanel .productprice{display: table-cell; vertical-align: top; width: 1%;}
.profilecontainer .totalcountpanel{float: left; width: 100%; margin: 25px 0px 15px 0px; padding: 0px;}
.profilecontainer .totalcountpanel table{border: none; margin: 0px;}
.profilecontainer .totalcountpanel table tr td{border: none; color: #5d5d5d; font-weight: 500; font-size: 15px; text-align: right;}
.profilecontainer .totalcountpanel table tr td.righttd{width: 150px;}
.profilecontainer .totalcountpanel table tr td strong{font-size: 16px; font-weight: 700; color: #000;}
.profilecontainer .orderaddress{float: left; width: 100%;}
.profilecontainer .orderaddress h3{color: #5d5d5d; text-align: left; font-size: 16px; font-weight: 600; letter-spacing: 1px; text-transform: uppercase; margin:10px 0px 20px 0px;}
.profilecontainer .orderaddress p{color: #687180; text-align: left; margin: 0px 0px 15px 0px; font-weight: 400;}
.profilecontainer .orderaddress p.email{margin: 0px 0px 2px 0px;}
.profilecontainer .ordermode{float: left; width: 100%;}
.profilecontainer .ordermode h4{color: #433b39; text-align: right; font-size: 16px; font-weight: 600; letter-spacing: 1px; text-transform: capitalize; margin:10px 0px 5px 0px;}
.profilecontainer .ordermode p{color: #687180; text-align: right; margin: 0px 0px 15px 0px; font-weight: 400;}
.profilecontainer .rightprofile .table tbody tr td span{    color: white;line-height: 35px;font-weight: 400;background: red;
padding: 5px 10px;border-radius: 30px;}
.profilecontainer .rightprofile .table tbody tr td button{display: inline-flex;align-items: center;padding:5px 10px;
    color: #FFF;font-size: 14px;line-height: 22.346px;background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);border: none;}

    .profilecontainer .rightprofile .table tbody tr td button:hover {
      color: #fff;
      transform: scale(1.05);
  }

  .profilecontainer .rightprofile .noSubsTag{
    font-size: 24px;
    margin: 0;
    font-weight: normal;
  }
  .nodata{padding: 10px 0px;}
@media (min-width: 1920px) {
  /* 07012023 */
  .profilecontainer .profileleftpanel .usercolumn .userimg img{width: 120px; height: 120px;}
  .profilecontainer .profileleftpanel .usercolumn h3{font-size: 24px;}
  .profilecontainer .profileleftpanel .usernavlist ul li a{font-size: 18px; padding: 16px 10px 16px 70px;}
  .profilecontainer .profileleftpanel .usernavlist ul li a i{width: 28px; height: 42px; line-height: 41px;}
  .profilecontainer .rightprofile h2{font-size: 25px;}
  .profilecontainer .rightprofile .form-group label{font-size: 18px;}
  .profilecontainer .rightprofile .form-group .form-control{font-size: 17px;}
  .profilecontainer .rightprofile .submitbtn{font-size: 18px;}
  .profilecontainer .rightprofile .dl-horizontal{font-size: 17px;}
  .profilecontainer .profileleftpanel .usercolumn p{font-size: 16px;}
  .profilecontainer .profileleftpanel .usercolumn p a{font-size: 18px;}
  .dashboardbtx .dashctcontent p{font-size: 18px;}
  .dashboardbtx .dashctcontent h3{font-size: 22px;}
  .dashboardbtx .dashicon i{width: 80px; height: 80px; font-size: 36px;}
  .profilecontainer .rightprofile h2{font-size: 28px;}
  .profilecontainer .rightprofile .table thead tr th{font-size: 17px;}
  .profilecontainer .rightprofile .table tbody tr td{font-size: 17px;}
  .profilecontainer .rightprofile .table tbody tr td .badge{font-size: 15px; padding: 8px 10px;}
  .profilecontainer .rightprofile .table tbody tr td .btn{font-size: 20px; min-width: 110px;}
  .profilecontainer .rightprofile .form-group .formfloating label{font-size: 17px;}
  .profilecontainer .rightprofile .form-group .formfloating .form-control{height: 54px; font-size: 17px;}
  .theme-primary-btn{font-size: 18px; padding: 11px 30px;}
  .profilecontainer .rightprofile .form-group .formfile .form-control{height: 54px; font-size: 17px; line-height: 40px;}
  .tabbtx .nav-tabs .nav-item .nav-link{font-size: 18px;}
  
}
@media only screen and (min-width: 1599px){
  .profilecontainer .profileleftpanel{padding: 70px 0px 20px 0px;}
}
@media only screen and (max-width: 1599px){
  .profilecontainer .profileleftpanel{padding: 40px 0px 20px 0px;}
}

@media (max-width: 1399px) {
  .dashboardbtx{padding: 20px;}
  .dashboardbtx .dashctcontent h3{font-size: 17px;}
  .profilecontainer .profileleftpanel .usercolumn .userimg img{width: 110px; height: 110px;}
  .profilecontainer .profileleftpanel .usernavlist ul li a{font-size: 14px;}
  .profilecontainer .profileleftpanel .usercolumn h3{font-size: 19px;}
  .profilecontainer .profileleftpanel .usercolumn p a{font-size: 14px;}
  .profilecontainer .rightprofile .table thead tr th{font-size: 13px;padding: 10px 5px;}
  .profilecontainer .rightprofile .table tbody tr td{font-size: 13px;}
  .profilecontainer .rightprofile .table tbody tr td {min-width: 100%;padding: 10px 7px;}
  .profilecontainer .rightprofile .table tbody tr td p{font-size: 13px;}
  .profilecontainer .rightprofile .dl-horizontal{font-size: 13px;}
  .theme-primary-btn{padding: 10px 22px; font-size: 13px;}
  .profilecontainer .rightprofile .form-group .formfloating .form-control{font-size: 13px; height: 40px;}
  .profilecontainer .rightprofile .form-group .formfile .form-control{height: 40px; font-size: 13px; line-height: 28px;}
  .profilecontainer .rightprofile .dl-horizontal{font-size: 13px;}
  .profilecontainer .accountinnerheading{font-size: 13px;}
  .profilecontainer .accountinnerheading .backbtn{font-size: 12px;}
  .profilecontainer .itempanel .itempanelheading{font-size: 12px;}
  .profilecontainer .itempanel .productitempanel .productmedia img{width: 140px;}
  .profilecontainer .itempanel .productitempanel .productdesc .producttitle{font-size: 13px; margin: 0px 0px 5px 0px;}
  .profilecontainer .itempanel .productitempanel .productdesc .qtypanel .ctiinner{font-size: 13px; margin: 0px 15px 5px 0px;}
  .profilecontainer .itempanel .productitempanel .productdesc .statuspanel .stinner{font-size: 13px;}
  .profilecontainer .orderaddress h3{font-size: 14px; margin: 5px 0px 10px 0px;}
  .profilecontainer .orderaddress p{font-size: 13px;}
  .profilecontainer .ordermode h4{font-size: 13px;}
  .profilecontainer .ordermode p{font-size: 13px;}

  .profilecontainer .rightprofile .noSubsTag{
    font-size: 20px;
  }
  .profilecontainer .rightprofile {
    margin-top: 0px;
    padding: 10px 10px;
}
}
@media (max-width: 1199px){
  .tabbtx .nav-tabs .nav-item .nav-link{font-size: 13px; padding: 10px 17px;}
}
@media (max-width: 991px){
  .profilecontainer .rightprofile{padding: 10px 0px;}
  .profilecontainer .rightprofile .table-responsive{overflow: auto;}

  .profilecontainer .rightprofile .table tbody tr td{font-size: 11px;padding: 4px 5px;}
  /* .profilecontainer .rightprofile .table thead tr{display: block;} */
  .profilecontainer .rightprofile .table thead tr th {
    font-size: 11px;
}
  /* .profilecontainer .rightprofile .table tbody tr td{display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; justify-content: start; align-items: center; margin: 0px; padding: 7px 0px;} */
  .profilecontainer .rightprofile .table tfoot tr td{display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; justify-content: start; align-items: center; margin: 0px; padding: 7px 0px;}
  .profilecontainer .rightprofile .table tbody tr td:before{content: attr(data-th); font-weight: 600; color: #39378A; -ms-flex: 0 0 140px; flex: 0 0 140px; max-width: 140px;}
  .profilecontainer .rightprofile .table tbody tr td{border: none; -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%;}
  .profilecontainer .rightprofile .table tbody tr td:first-child{border-left: none;}
  .profilecontainer .rightprofile .table tbody tr td:last-child{border-right: none;}
  .profilecontainer .rightprofile .table tfoot tr td:first-child{border-left: none;}
  .profilecontainer .rightprofile .table tfoot tr td:last-child{border-right: none;}
  /* .profilecontainer .rightprofile .table>:not(caption)>*{display: block; border: none;} */
  .profilecontainer .rightprofile .table tbody tr{border: 1px solid #eaeaea; background: #fff; -webkit-border-radius: 8px; border-radius: 8px; padding: 12px 20px 8px 20px; margin: 0px 0px 10px 0px; -webkit-box-shadow: 0px 0px 10px #e5e5e5; box-shadow: 0px 0px 10px #e5e5e5;}
  .profilecontainer .rightprofile .table tfoot tr{border: 1px solid #eaeaea; background: #fff; -webkit-border-radius: 8px; border-radius: 8px; padding: 12px 20px 8px 20px; margin: 0px 0px 10px 0px; display: block; -webkit-box-shadow: 0px 0px 10px #e5e5e5; box-shadow: 0px 0px 10px #e5e5e5;}
  .profilecontainer .rightprofile .table tbody tr td + td{border-top: 1px solid #fff;}
  .profilecontainer .profileleftpanel{padding: 20px 0px;}
  .profilecontainer .profileleftpanel .usercolumn{padding: 0px 20px 18px 20px}
  .profilecontainer .profileleftpanel .usernavlist ul li a{padding: 10px 10px 10px 58px;}
  .profilecontainer .profileleftpanel .usernavlist ul li a i{left: 18px;}
  .profilecontainer .profileleftpanel .usercolumn:after{left: 18px; right: 18px;}
  .profilecontainer .rightprofile .table tbody tr td .btn{width: auto; height: auto; line-height: initial; background: none; border: none;}
  .profilecontainer .rightprofile .table tbody tr td .btn:hover{background: none; border: none;}
  .profilecontainer .rightprofile .table tfoot tr td .btn{width: auto; height: auto; line-height: initial; background: none; border: none;}
  .profilecontainer .rightprofile .table tfoot tr td .btn:hover{background: none; border: none;}
  .profilecontainer .paginationright{margin-top: 0px;}
  .profilecontainer .rightprofile .table tbody tr.dynamic-fieled.trfirst{display: none !important;}
  .profilecontainer .rightprofile .table tbody tr td .form-group{width: 100%;}
  .profilecontainer .rightprofile .table tfoot tr{display: block;}
  .profilecontainer .rightprofile .table tfoot tr td{border: none; width: 100%;}
  .tabbtx .nav-tabs .nav-item .nav-link{font-size: 13px; padding: 8px 9px;}
  .profilecontainer .rightprofile .dl-horizontal{font-size: 13px;}
  .profilecontainer .rightprofile .dl-horizontal hr{margin-top: 10px; margin-bottom: 10px;}


}
@media (min-width: 768px){
  .ProfileBtn{display: none;}
}
@media (max-width: 767px){
  .ProfileBtn{position: absolute; right: 0px; top: -16px; z-index: 1; float: left; color: #fff; font-size: 13px; background: #AB7F5A; border: 1px solid #CACDA5; text-transform: uppercase; width: 26px; height: 26px; text-align: center; line-height: 24px; -webkit-border-radius: 50%; border-radius: 50%; padding: 0px; text-decoration: none; margin: 0px 0px 0px 0px;}
  .ProfileBtn i{padding-right: 0px;}
  .ProfileBtn:focus{color: #fff; text-decoration: none;}
  .ProfileBtn:hover{color: #fff; text-decoration: none;}
  .profilemobilepanel{position: fixed; top: 0; padding: 0 !important; height: 100%; left: 0; margin-left: -100%; background: #fff; max-height: 100%; overflow: auto; margin-right: 0; transition: all 0.3s; width: calc(100% - 60px); z-index: 99999;}
  .profilemobilepanel.showDashSide{margin-left: 0;}
  .layout-expanded4 .profilemobilepanel{margin-left: 0px; padding: 0px !important; -webkit-box-shadow: none; box-shadow: none;}
  .layout-expanded4{overflow: hidden;}
  .layoutovarlay4{position: fixed; z-index: 1000; top: 0; right: 0; height: 100% !important; left: 0; display: none; background: #181C21; opacity: .5; cursor: pointer;}
  .layout-expanded4 .layoutovarlay4{display: block;}
  .profilecontainer .rightprofile h2{font-size: 18px;}
  .profilecontainer .rightprofile{padding: 20px;}
  .profilecontainer .rightprofile .submitbtn{font-size: 16px; padding: 8px 20px; min-width: 130px;}
  .profilecontainer .rightprofile .form-group .form-control{height: 30px;}
  .profilecontainer .rightprofile .cartbxc{padding: 15px 15px 25px 15px;}
  .profilecontainer .rightprofile .dl-horizontal dt strong{color: #39378A; margin: 0px 0px 5px 0px; display: block;}
  .profilecontainer .rightprofile{-webkit-border-radius: 0px; border-radius: 0px;}
  .profilecontainer .rightprofile{padding: 10px; background: #fff;}
  .profilecontainer .prpcontainer{padding: 0px; background: none;}
  .ProfileBtn{top: -23px;}
  .dashboardbtx .dashctcontent p{font-size: 13px;}
  .dashboardbtx .dashctcontent h3{font-size: 15px;}
  .dashboardbtx .dashicon i{float: left; width: 40px; height: 40px; position: relative; font-size: 19px;}
  .profilecontainer .profileleftpanel .usercolumn .userimg img{width: 90px; height: 90px;}
  .profilecontainer .profileleftpanel .usercolumn h3{font-size: 17px;}
  .profilecontainer .tab-content .tab-pane{padding: 15px; background: #fff;}
  .theme-primary-btn{font-size: 13px;}
  .profilecontainer .accountinnerform .dl-horizontal span.rightcolumn{display: none;}
  .profilecontainer .itempanel .productitempanel .productmedia img{width: 100px;}
  
}
@media (max-width: 575px){
  /* 07012022 */
  .profilecontainer .rightprofile .deliveryaddressbtx h4{font-size: 18px;}
  .profilecontainer .rightprofile .deliveryaddressbtx p{font-size: 14px;}
  .profilecontainer .rightprofile .deliveryaddressbtx .invoicebtn{font-size: 13px; padding: 12px 20px 11px 20px; text-transform: capitalize;}
  .profilecontainer .rightprofile .form-group label{font-size: 13px;}
  .profilecontainer .rightprofile .form-group .form-control{font-size: 13px;}
  .profilecontainer .rightprofile h2{margin: 0px 0px 10px 0px; font-size: 18px;}
  .profilecontainer .rightprofile .table tbody tr{padding: 9px 15px 6px 15px;}
  .profilecontainer .rightprofile .table tbody tr td{padding: 4px 5px;}
  .profilecontainer .rightprofile .form-group .formfloating .form-control{height: 38px;}
  .tabbtx .nav-tabs .nav-item{margin: 0px 5px 5px 0px;}
  .tabbtx .nav-tabs .nav-item .nav-link{border: 1px solid #e3deef;}
  .tabbtx .nav-tabs .nav-item .nav-link.active{border-bottom-color: #e3deef;}
  .tabbtx .nav-tabs .nav-item .nav-link:hover{border-bottom-color: #e3deef;}
  .profilecontainer .rightprofile .form-group{margin-bottom: 20px;}
  .profilecontainer .itempanel .productitempanel .productdesc{padding: 0px 10px;}
  .profilecontainer .itempanel .productitempanel .productmedia img{width: 70px;}
  .profilecontainer .rightprofile .table tfoot tr td{padding: 3px 0px;}
  .profilecontainer .rightprofile .table tfoot tr td{font-size: 13px;}
  .profilecontainer .totalcountpanel table tr td strong{font-size: 13px;}
  .profilecontainer .accountinnerform .orderdatepanel .dl-horizontal dd{font-weight: 500;}
}
@media (max-width: 440px){
  .profilecontainer .rightprofile .table tbody tr td{font-size: 12px;}
  .profilecontainer .rightprofile .table tbody tr td:before{-ms-flex: 0 0 113px; flex: 0 0 113px; max-width: 113px;}
  .profilecontainer .rightprofile .table tbody tr td .badge{font-size: 11px; min-width: 90px;}
  .dascolumns{-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
}

/*
  font-family: 'Inter', sans-serif;
*/



.paginationright{float: right; width: 100%; margin-top: 25px; margin-bottom: 30px;}
.paginationright ul{float: none; margin: 0px; padding: 0px;}
.paginationright ul li{display: inline-block; vertical-align: top; list-style: none; background: none; color: #A4A4A4; font-size: 13px; font-weight: 400; padding: 0px; line-height: 34px; min-width: 34px; height: 34px; text-align: center; margin: 2px 1px 2px 1px; -webkit-border-radius: 3px; border-radius: 3px; border: 1px solid #E4E4E4;}
.paginationright ul li a{text-decoration: none; color: #202020; display: block;}
.paginationright ul li.active{color: #2F2F2F; border-color: #2F2F2F;}
.paginationright ul li.prev a{color: #2F2F2F; font-size: 14px; font-weight: 500; padding: 0px 10px;}
.paginationright ul li.prev a i{font-size: 18px;}
.paginationright ul li.next{border-color: #CFCFCF;}
.paginationright ul li.next a{color: #2F2F2F; font-size: 14px; font-weight: 500; padding: 0px 10px;}
.paginationright ul li.next a i{font-size: 18px;}
.paginationright ul li.dotted{border: none; min-width: 20px;}