input::-webkit-date-and-time-value{ text-align:left; }
.frmFill input {
    color: #6A6A6A;
    font-size: 14px;
    height: 50px;
    margin: 0;
    padding: 8px 15px 8px 55px;
    width: 100%;
    text-align: left !important;
    justify-content: left;
    background: #fff;
    border: none;
    font-family: 'GothamBold';
}
/* .frmFill input [type= ‘date’ ]{
    position: relative;
} */
.frmFill input [type= ‘date’ ]::after{
    color: #f3f3f3;
    content: attr(placeholder);
 } 
.react-datepicker-wrapper{
    width: 100% !important;
}
/* .react-datepicker-popper{z-index: 9;} */

.frmFill input[type="date"]:focus-visible {
    outline: none;
}
.clientSldr .owl-prev::before,.clientSldr .owl-next::before {
    position: inherit;
}

/* .profilecontainer{
    margin-top: 85px;
} */
.required{
    color: red;
}
.successContainer{
    margin-top: 150px;
}
.success {
    padding: 40px 0px;
    border: 5px solid green;
    margin: 30px;
    box-shadow: 0px 0px 5px #000;
    border-radius: 5px;
}

.success i {
    color: green;
    font-size: 80px;
}
.success h4 {
    color: green;
    font-size: 50px;
}

.knowledgeVideo img{
    width: 100%;
}
.knowledgeVideo .aboutImg{
    content: none;
    box-shadow: none    ;
    margin-bottom: 10px;
}
.knowledgeVideo .aboutImg::before {
    content: none;
}

.failed i{
    color: #ff0000;
    border: 8px solid #ff0000;
    border-radius: 50%;
    width: 100px;
}

.cursor-pointer{
    cursor: pointer;
}

.loaddiv{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c8d6d8;
    z-index: 999;
    top: 0;
    left: 0;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  .dtapikr{
    width: 100%;
    background: #fff;
    border-radius: 5px;
  }

  .form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}

  @media only screen and (max-width: 991px){
    .dtapikr{
        padding-left: 45px;
      }
      .frmFill input[type="date"]{padding-left: 0;}
  }