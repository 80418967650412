/* input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:active,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:active,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #6A6A6A !important;
}

input:-webkit-autofill::first-line,
input:-internal-autofill-previewed,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
:-moz-placeholder {
  -webkit-text-fill-color: #6A6A6A !important;
} */

::placeholder {
  color: #6A6A6A !important;
  opacity: 1 !important;
  overflow: visible;
}

body{ padding: 0px; margin: 0px; list-style: none; font-size: 15px; font-weight: normal; font-family: 'gothammedium'; color:#000;overflow-x: hidden; }
p{padding: 0px; margin: 0px;}
a{padding: 0px; margin: 0px; outline: none; text-decoration: none !important;}
ul{ list-style: none; padding: 0px; margin: 0px;}
label{ list-style: none; padding: 0px; margin: 0px;}
ul li{ padding: 0px; margin: 0px;}
h1, h2, h3, h4, h5, h6{padding: 0px; margin: 0px;}
input[type="text"]::-webkit-placeholder { opacity: 1;  }
input[type="text"]::-moz-placeholder { opacity: 1;}
input[type="password"]::-webkit-placeholder { opacity: 1;}
input[type="password"]::-moz-placeholder { opacity: 1;}
input[type="search"]::-webkit-placeholder { opacity: 1;}
input[type="search"]::-moz-placeholder { opacity: 1;}
input[type="email"]::-webkit-placeholder { opacity: 1; }
input[type="email"]::-moz-placeholder { opacity: 1;}
input[type="tel"]::-webkit-placeholder { opacity: 1;}
input[type="tel"]::-moz-placeholder { opacity: 1;}
textarea::-moz-placeholder { opacity: 1;  }
textarea::-webkit-placeholder { opacity: 1;}
a:hover{ outline: none !important; text-decoration: none;}
a:active{ outline: none !important; text-decoration: none;}
a{ text-decoration: none !important;}
a, button, input[type="submit"], ::before, ::after{
transition: all 0.3s ease-in-out;
-webkit-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
}
input[type="submit"]{cursor: pointer; border: 0px; outline: none; background: none;}

img{max-width: 100%; height: auto;}
p, h1, h2, h3, h4, h5, h6{margin: 0 0 13px;}
p:last-child{margin-bottom: 0px;}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child{margin-bottom: 0px;}
figure{margin: 0px;}

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot');
    src:  url('../fonts/icomoon.eot?etoy0n#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?etoy0n') format('truetype'),
      url('../fonts/icomoon.woff?etoy0n') format('woff'),
      url('../fonts/icomoon.svg?etoy0n#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-Group:before {
    content: "\e900";
  }
  .icon-maps-and-flags-1:before {
    content: "\e901";
  }
  .icon-Arrow-1-3:before {
    content: "\e902";
  }
  .icon-Arrow-1-2:before {
    content: "\e903";
  }
  .icon-Vector-12:before {
    content: "\e904";
  }
  .icon-Arrow-1-1:before {
    content: "\e905";
  }
  .icon-Vector-11:before {
    content: "\e909";
  }
  .icon-Vector-10:before {
    content: "\e90c";
  }
  .icon-Vector-9:before {
    content: "\e90d";
  }
  .icon-Arrow-1:before {
    content: "\e90e";
  }
  .icon-Vector-8:before {
    content: "\e90f";
  }
  .icon-Vector-7:before {
    content: "\e910";
  }
  .icon-Vector-6:before {
    content: "\e911";
  }
  .icon-Vector-5:before {
    content: "\e912";
  }
  .icon-instagram1:before {
    content: "\e90a";
  }
  .icon-instagram:before {
    content: "\e907";
  }
  .icon-linkedin:before {
    content: "\e906";
  }
  .icon-twitter:before {
    content: "\e90b";
  }
  .icon-facebook-logo:before {
    content: "\e91b";
  }
  .icon-pinterest:before {
    content: "\e934";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
  
  @font-face {
    font-family: 'GothamBold';
    src: url('../fonts/GothamBold.eot');
    src: url('../fonts/GothamBold.eot') format('embedded-opentype'),
         url('../fonts/GothamBold.woff2') format('woff2'),
         url('../fonts/GothamBold.woff') format('woff'),
         url('../fonts/GothamBold.ttf') format('truetype'),
         url('../fonts/GothamBold.svg#GothamBold') format('svg');
}
/* @font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammedium.eot');
    src: url('../fonts/gothammedium.eot') format('embedded-opentype'),
         url('../fonts/gothammedium.woff2') format('woff2'),
         url('../fonts/gothammedium.woff') format('woff'),
         url('../fonts/gothammedium.ttf') format('truetype'),
         url('../fonts/gothammedium.svg#gothammedium') format('svg');
} */

@font-face {
  font-family: 'gothammedium';
  src: url('../fonts/gothammedium_1-webfont.woff2') format('woff2'),
       url('../fonts/gothammedium_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



.headersec.fixed_header, .navbar-brand{
transition: all 0.3s ease-in-out;
-webkit-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
}
.headersec.fixed_header{position: fixed; top: 0; left: 0; width: 100%; z-index: 999;
-moz-box-shadow: 0 0 8px -5px #333;
-webkit-box-shadow: 0 0 8px -5px #333;
box-shadow: 0 0 8px -5px #333;}


@-webkit-keyframes animationFade{0%{opacity:0;-webkit-transform:translateY(-20px);transform:translateY(-20px);}
100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0);}
}
@keyframes animationFade{0%{opacity:0;-webkit-transform:translateY(-20px);-ms-transform:translateY(-20px);transform:translateY(-20px);}
100%{opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}}
.headersec.fixed_header{top:0;left:0;right:0;position:fixed;margin:0;z-index:1002;-webkit-animation-name:animationFade;-o-animation-name:animationFade;animation-name:animationFade;-webkit-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;-webkit-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;-moz-box-shadow:0 0 8px -5px #333;-webkit-box-shadow:0 0 8px -5px #333;box-shadow:0 0 8px -5px #333;}



/*
font-family: 'GothamBold';
*/

.hderinr{display: flex; position: relative; align-items: center; justify-content: space-between;}
.hdrlgo{width: auto;}
.hdrmnu{width: auto;}
.hdrmnu li{margin: 0 60px 0 0;}
.hdrmnu li a{display: inline-block;color: #393939;font-size: 22px;line-height: 28px;letter-spacing: 1.1px;}
.hdrmnu li a.active, .hdrmnu li a:hover{color: #00799B; }
.hdrmnu li:last-child{margin: 0;}
.headersec{width: 100%; 
  /* position: absolute; top: 0; left: 0;  */
  padding: 32px 0;
   /* z-index: 99; */
   background: #fff;}
.hfrBtn{display: inline-flex; align-items: center; padding: 0 45px 0 7px; color: #FFF; height: 55px; 
font-size: 20.111px; line-height: 22.346px; background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);font-family: 'GothamBold'; }
.hfrBtn span{background: #00799B; display: flex; align-items: center; justify-content: center;
width: 49px; height: 44px; margin: 0 23px 0 0;}
.hfrBtn:hover{color: #fff; transform: scale(1.1);}

.bannerSec{position: relative; width: 100%;}
.bnrimg{position: relative; width: 100%;}
.bnrimg > img{object-fit: cover; width: 100%;}
.bnrimg::before{position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.50);}
.bnrtxt{position: absolute; width: 100%; bottom: 180px; left: 0;}
.bnrtxtinr{max-width: 650px;}
.bnrtxtinr p{color: #FFF;font-size: 25px;line-height: 45px;}
.bnrtxtinr h1{color: #FFF;font-size: 85px;line-height: 90px;margin: 0 0 22px;}
.bnrtxtinr ul{display: flex; margin: 45px 0 0;}
.bnrtxtinr li a{display: flex; background: #00DAB9; width: 50px; height: 50px; align-items: center; justify-content: center;
color: #fff; border-radius: 100%;}
.bnrtxtinr li{margin: 0 18px 0 0;}
.bnrtxtinr li:last-child{margin: 0;}
.bnrimg::after{position: absolute; content: ""; bottom: 0; left: 0; width: 100%; height: 184px;
background: url(../images/bnerShape.png) no-repeat 0 0; background-size: 100% 100%;}
.bnerForm{position: absolute; bottom: 20px; left: 0; width: 100%; pointer-events: none;z-index: 99;}
.bnerfrmInr{float: right; background: rgba(37, 62, 80, 0.90); backdrop-filter: blur(5px); max-width: 603px; padding: 60px 55px 55px;
pointer-events: visible; width: 100%;}
.bnerfrmInr h3{color: #FFF;font-size: 45px;font-family: 'GothamBold';line-height: 20px; margin: 0; padding: 0 0 30px;}
.frmFill{width: 100%; margin: 22px 0 0;}
.frmFill label{display: block; color: #FFF; font-size: 18px; line-height: 20px;margin: 0 0 10px;}
.frmFill input[type="text"],
.frmFill input[type="email"],
.frmFill input[type="tel"],.frmFill input[type="date"]{width: 100%; height: 66px; background: #FFF; padding: 10px 20px 10px 70px; border: 0px; outline: none;
color: #6A6A6A;font-size: 16px;margin: 0;font-family: 'GothamBold';}
.frmFill input{width: 100%; height: 66px; background: #FFF; padding: 10px 20px 10px 70px; border: 0px; outline: none;
  color: #6A6A6A;font-size: 16px;margin: 0;}
.frmFill > div{display: flex; align-items: center; position: relative;}
.frmBtn{margin: 29px 0 0;}
.frmBtn button{border: 0px; padding: 0; color: #FFF; height: 74px;background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);font-size: 20px;line-height: 70px;;
width: 100%;font-family: 'GothamBold';}
.frmFill span{position: absolute; left: 27px; color: #00DAB9; pointer-events: none;}
.frmFill input[type="text"]::placeholder,
.frmFill input[type="email"]::placeholder,
.frmFill input[type="tel"]::placeholder{opacity: 1; color: #6A6A6A;}
.frmFill input::placeholder{opacity: 1; color: #6A6A6A;}
.frmBtn button span{margin-left: 19px; font-size: 15px;}
.frmBtn button:hover{transform: scale(1.05);}

.featuresSec{padding: 97px 0 155px; width: 100%; position: relative;}
.featureCon{text-align: center; max-width: 600px; margin: 0 auto;}
.featureCon p{color: #6D6D6D;font-size: 20px;line-height: 35px;}
.featureCon h2{color: #172935;font-size: 90px;line-height: 100px;letter-spacing: -3.6px;font-family: 'GothamBold';margin: 0 0 35px;}
.featureCon figure{margin: 0 0 72px;}
.redBtn{display: inline-flex; align-items: center; height: 61px; padding: 0 30px; color: #FFF;font-size: 18px; line-height: 20px;
background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);    cursor: pointer; }
.redBtn span{margin-left: 17px; font-size: 15px;}
.redBtn:hover{color: #fff; transform: scale(1.1);}
.featureCon .redBtn{margin: 60px 0 0;font-family: 'GothamBold';}

.whySec{width: 100%; position: relative; padding: 185px 0 124px; background: #C3F2FF; display: inline-block;}
.pageHding{text-align: center;}
.pageHding p{color: #323232;font-size: 20px;line-height: 35px; margin: 0 0 15px;}
.pageHding h2{color: #172935;font-size: 90px;font-family: 'GothamBold';line-height: 110px; letter-spacing: -3.6px;margin: 0 0 15px;}
.whtBtmCon{width: 100%; margin: 42px 0 0;}
.whtBtmCon p{color: #323232; font-size: 20px;line-height: 35px;}
.whyBx{width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center;padding: 0 20px;}
.whyBx p{color: #235867; font-size: 15px;font-weight: 500;line-height: 22px;}
.whyBx h3{color: #172935; font-size: 24px;font-weight: 700; margin: 0 0 20px;}
.whyBx figure{display: flex; align-items: center; justify-content: center; width: 185px; height: 180px; margin: 0 0 40px; position: relative;}
.whyPnel{margin: 100px 0 0; position: relative; margin-right: -100px !important; max-width: 1390px;float: right; pointer-events: none;}
.whyModel{position: absolute; left: -36%; bottom: -124px; }
.whyBx i{position: absolute; color: #FFF; font-size: 82px; width: 82px;}
.whyBx figure::before{position: absolute; content: ""; right: -75%; top: 50%; transform: translateY(-50%); width: 109px;
height: 19px; background: url(../images/whyArw1.png) no-repeat 0 0; }
.whyPnel .row > div:nth-child(odd) .whyBx figure::before{background: url(../images/whyArw2.png) no-repeat 0 0;}
.whyPnel .row > div:last-child .whyBx figure::before{content: none;}

.clientSec{padding: 82px 0 174px;}
.clientLgo{width: 100%; display: flex; align-items: center; justify-content: center; border-right: 1px solid #BDBDBD;height: 206px; }
.clientSldr{border: 1px solid #BDBDBD; width: 100%; margin: 43px 0 0;}
.clientLgo img{width: auto !important; display: inline-block !important;}
.clientSldr .owl-nav{display: flex !important; align-items: center; justify-content: center; position: absolute; bottom: -92px; width: 100%;}
.clientSldr .owl-nav button{border: 1px solid #172935 !important; width: 59px; height: 59px; border-radius: 100%; 
display: flex; align-items: center; justify-content: center; color: #172935 !important;}
.clientSldr .owl-nav button span{display: none;}
.clientSldr .owl-prev{margin-right: 14px;}
.clientSldr .owl-prev::before{position: absolute; content: "\e903"; font-family: 'icomoon';}
.clientSldr .owl-next::before{position: absolute; content: "\e902"; font-family: 'icomoon';}
.clientSldr .owl-nav button:hover{background: #172935 !important; color: #fff !important;}
.clientSldr .owl-item:not(.active)
+ .owl-item.active .clientLgo,
.clientSldr .owl-item:not(.active)
+ .owl-item.active
+ .owl-item.active
+ .owl-item.active .clientLgo,
.clientSldr .owl-item:not(.active)
+ .owl-item.active
+ .owl-item.active
+ .owl-item.active
+ .owl-item.active
+ .owl-item.active .clientLgo{ background: linear-gradient(180deg, #E8E8E8 -17.23%, rgba(245, 245, 245, 0.00) 100%); }

.aboutSec{width: 100%;}
.aboutMain{max-width: 1980px; position: relative; margin: 0 auto; padding: 147px 0;}
.aboutPnl{display: flex; align-items: center; justify-content: space-between; flex-direction: row-reverse;}
.aboutCon{width: 100%; max-width: 635px; position: relative;}
.aboutCon p{color: #FFF; font-size: 20px;line-height: 35px;}
.aboutCon h2{color: #FFF; font-family: 'GothamBold';font-size: 90px;line-height: 110px;letter-spacing: -3.6px;margin: 0 0 42px;}
.aboutCon .redBtn{margin: 65px 0 0;}
.aboutImg{width: 100%; max-width: 1036px; position: relative;
box-shadow: 0px 33px 72px 0px rgba(0, 0, 0, 0.10), 0px 131px 131px 0px rgba(0, 0, 0, 0.09), 0px 295px 177px 0px rgba(0, 0, 0, 0.05), 0px 525px 210px 0px rgba(0, 0, 0, 0.01), 0px 820px 230px 0px rgba(0, 0, 0, 0.00); }
.playVideo{display: flex; align-items: center; justify-content: center; background: #00DAB9; width: 78px; height: 78px;
border-radius: 100%; position: absolute; top: 50%; left: 50%; color: #fff; font-size: 19px; transform: translate(-50%, -50%);}
.aboutImg::before{position: absolute; content: ""; background: rgba(0, 0, 0, 0.40); top: 0; left: 0; width: 100%;
height: 100%;}
.playVideo:hover{background: #172935; color: #fff;}
.aboutMain::before{background: #00799B; position: absolute; bottom: 0; top: 0; width: 58%; content: ""; z-index: -1;}
.abt_icn{position: absolute; bottom: 0; left: 0;}

.fterPay h3, .foterLink h3, .foterinfo h3{display: flex; align-items: center; color: #FFF; font-size: 25px;font-family: 'GothamBold'; margin: 0 0 35px;}
.foterLink, .foterinfo{width: 100%;}
.fterPay h3 i, .foterLink h3 i, .foterinfo h3 i{margin-right: 14px;}
.fterPay p{color: #FFF; font-size: 18px;line-height: 30px;}
.fterPay p a{color: #00F69B; }
.fterPay p a:hover{color: #fff;}
.foterSec{background: #172935; width: 100%; padding: 66px 0 72px;}
.fterPay ul{display: flex; margin: 0 0 68px;}
.fterPay li{margin: 0 9px 0 0; background: #FFF; width: 61px; height: 36px; display: flex; align-items: center; justify-content: center;
padding: 2px;}
.fterPay li:last-child{margin: 0;}
.foterLink ul{width: 100%; display: inline-block;}
.foterLink li{color: #FFF; font-size: 18px; font-weight: 400; padding-left: 22px; position: relative;
margin-bottom: 14px;}
.foterLink li a{color: #fff;}
.foterLink li::before{width: 8px; height: 8px; border-radius: 100%; border: 1.5px solid #00DAB9; position: absolute; content: "";
left: 0; top: 4px;}
.foterLink li a:hover{color: #00DAB9;}
.media{display: flex;}
.media-object{flex: 1;}
.foterinfo li{margin: 0 0 34px;}
.foterinfo li:last-child{margin: 0;}
.foterinfo li .media{align-items: center;}
.foterinfo p{color: #FFF; font-size: 14px;line-height: 23px;max-width: 200px;}
.foterinfo .media > span{background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%); color: #FFF; margin: 0 23px 0 0;
width: 44px; height: 44px; display: flex; align-items: center; justify-content: center; border-radius: 100%; font-size: 15px;}
.foterinfo p a{color: #FFF;}
.foterinfo p a:hover{color: #00DAB9;}
.foterinfo b{color: #FFF;font-size: 20px;font-weight: 500;line-height: 23px;}
.foterinfo b a{color: #FFF;}
.foterinfo b a:hover{color: #00DAB9;}
.foterlft{display: flex; flex-direction: column; align-items: center; justify-content: center; }
.frtsocial{text-align: left; margin: 40px 0 0;}
.frtsocial h3{color: #FFF; font-size: 20px;line-height: 32px; margin: 0 0 24px;}
.frtsocial li{margin: 0 19px 0 0;}
.frtsocial li a{display: flex; align-items: center; justify-content: center; border-radius: 100%; width: 50px; height: 50px;
color: #fff; background: #00DAB9;}
.frtsocial ul{display: flex;}
.frtsocial li{margin: 0 19px 0 0;}
.frtsocial li a:hover{background: #FFF; color: #172935;}
.foterLink, .foterinfo, .fterPay{margin: 30px 0 0;}
.foterLink{padding-left: 100px;}

.featureBx{width: 100%; margin: 78px 0 0; max-width: 300px; pointer-events: visible; position: relative;}
.featureBx h3{color: #172935; font-size: 30px;line-height: 34px;letter-spacing: -1.2px;font-family: 'GothamBold';margin: 0 0 40px;}
.featureLst{pointer-events: none; position: absolute; top: 0; width: 100%; left: 0;}
.featureLst .row{margin: 0;}
.featureLst .row > div{padding: 0;}
.featureLst .row > div:nth-child(odd) .featureBx{text-align: center;}
.featureLst .row > div:nth-child(even){display: flex; justify-content: flex-end;}
.featureBx::before{position: absolute; content: ""; width: 136px; height: 141px;}
.featureLst .row > div:nth-child(1) .featureBx::before{bottom: 10%; right: -70%; background: url(../images/fetureArw1.png) no-repeat 0 0;}
.featureLst .row > div:nth-child(2) .featureBx::before{bottom: 20%; left: -70%; background: url(../images/fetureArw2.png) no-repeat 0 0;}
.featureLst .row > div:nth-child(3) .featureBx::before{top: -10%; right: -70%; background: url(../images/fetureArw3.png) no-repeat 0 0;}
.featureLst .row > div:nth-child(4) .featureBx::before{top: -20%; left: -70%; background: url(../images/fetureArw4.png) no-repeat 0 0;}

.whySec::before{height: 132px; width: 100%; position: absolute; content: ""; background: url(../images/whyShape.png) no-repeat 0 0;
top: 0; left: 0; background-size: 100% 100%;}

.whyBx, .whtBtmCon{pointer-events: visible;}
.headersec.fixed_header{padding: 15px 0;}

::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar-thumb:horizontal {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.bannerimg{position: relative;}
.bannerimg img{width: 100%;height: 300px;object-fit: cover;}
.bannerimg h2{position: absolute; top: 50%;
  left: 10%;
  color: #fff;
  font-size: 50px;
  /* border-left: 5px solid #00f69b; */
  padding: 0px 0px 0px 0;}
.abut-con{padding: 30px 0px;}
.abubann img{width: 50%;float: right;
  margin: 0 0 0 15px;}

.abutxt h3{color: #172935;font-size: 40px;line-height: 60px;letter-spacing: -3.6px;font-family: 'GothamBold';
}
.abutxt p{color: #6D6D6D;font-size: 16px;line-height: 25px;text-align: justify;}

/*************************price***********************/

.price-section .row{max-width: 1555px; margin: 0 auto; }
.price-section{padding: 50px 0px 80px; position: relative;}
/* .price-section::before{position: absolute;content:url('assets/images/backprice.png');
bottom: 0;z-index: -1;max-width: 100%;} */
.price-header{border-bottom: 1px solid #DEDEDE;padding: 35px 0px 15px 0px;}
.price-header img{margin-bottom: 20px;width: 70px;height: 70px;}
.price-header h6{color: #172935;text-align: center;font-size: 20px;font-style: normal;font-weight: 400;
line-height: 34px;letter-spacing: -0.96px;}
.price-header h4{color: #00C0DB;text-align: center;font-size: 36px;font-style: normal;font-weight: 300;
line-height: 60px;}
.price-header h5{color: #172935;text-align: center;font-size: 18px;font-style: normal;font-weight: 500;
line-height: 70px; }
.price-header h3{color: #FFF;text-align: center;font-size: 16px;font-style: normal;font-weight: 400;
line-height: 34px; /* 170% */letter-spacing: -0.8px;text-transform: uppercase;background: #00799B;
margin-bottom: 40px;}
.price-body{padding: 30px 15px 15px 15px;}

.price1, .price2{max-width: 285px; border: 1px solid #DADADA;
background: #FFF;}
.price3{max-width: 285px; border: 1px solid #DADADA;
background: #F6FFFE;box-shadow: 0px 0px 15px 0px rgba(0, 121, 155, 0.20); }
.price4{max-width: 285px; border: 1px solid #DADADA; background: #FFF;}
.price3 .price-header{padding: 10px 11px 15px 11px;}
.footer-back{position: relative;width: 100%;height: 400px;background-color: #C3F2FF;}
/*************************price***********************/

.price-section .row > div{padding: 0px; border-right: 1px solid #DADADA; border-top: 1px solid #DADADA; transition: all 0.3s ease-in-out;
border-left: 1px solid transparent; }
.price-section .row > div > div{max-width: 100%; min-height: 1300px; }
.bredLink{position: absolute; width: 100%; top: 17%; left: 0; text-align: center;}
.bredLink h1{color: #FFF; font-family: 'GothamBold';font-size: 80px;line-height: 90px;margin: 0 0 20px;}
.bredLink ul{display: inline-flex; align-items: center;}
.bredLink li{position: relative; color: #FFF; font-size: 25px;line-height: 30px; padding-right: 22px; margin-right: 22px;}
.bredLink li a{color: #FFF;}
.bredLink li a:hover{color: #00C0DB; }
.bredLink li:last-child{padding: 0;margin: 0; color: #00C0DB;}
.bredLink li::before{position: absolute; content: "\e908"; font-family: 'icomoon'; color: #00C0DB; font-size: 16px; right: -6px;
top: 2px;}
.bredLink li:last-child::before{content: none;}
.price-section .row > div > div{border: 0px;}
.price-section .row > div:first-child{border-left: 1px solid #DADADA;}

.price1{border-bottom: 8px solid #00C0DB !important;}
.price2{border-bottom: 8px solid #00F69B !important;}
.price3{border-bottom: 8px solid #00799B !important;}
.price4{border-bottom: 8px solid #00DAB9 !important;}

.price-body li{position: relative; padding-left: 30px; color: #6D6D6D; font-size: 18px;line-height: 25px; margin: 0 0 18px;}
.price-body li::before{position: absolute; content: ""; left: 0px; top: 0px; width: 28px;height: 28px;
background: url('../images/check.png') no-repeat 0 0;}
.price-body li:last-child{margin: 0;}
.price-section .row > div:hover{transform: scale(1.05); border-left: 1px solid #DADADA;}

.price-section::before{position: absolute; content: ""; z-index: -1; bottom: 0; left: 0; right: 0; height: 527px;
background: #C3F2FF; z-index: -1; clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);}

ul.slimmenu {padding: 0 15px;}

/*************************************Knowledge**************************************/
.Knowledge{margin:50px 0px;}
.Knowledge{padding:10px 10px 10px 10px;border-radius: 10px;background: #00cec5;}
.jsthed {color: #304c60;
    text-align: center;
    padding: 10px;
    border-radius: 10px;font-size:44px;}
    /* .Knowledgelist ul{column-count: 2;} */
.Knowledgelist{padding-right: 10px;}    
.Knowledgelist ul li{position:relative;margin-left:30px;line-height:35px;margin-bottom: 10px;}
.Knowledgelist ul li a{color:#304c60;}
.Knowledgelist ul li::before{position:absolute;content:'';width:8px;height:8px;background:#fff;left: -18px;
    top: 13px;
    transform: rotate(45deg);}
    .knowledgeVideo{border-left: 2px solid #304c60;}

.fstbg{
  background: #00799B;
  height: 100%;
  
}
.scndbg{
  background: #00c0db;
  height: 100%;
}

.dshbox .card-header{
  color: #fff;
  padding: 10px;
}
.dshbox .card-body h6{
  font-size: 1.2rem;
  color: #fff;
}
.dshbox .card-body h2{
  font-size: 2.2rem;
  color: #fff;
}
.taglnk {
	font-size: 20px !important;
	/* background: rgba(9, 180, 204, 0.7); */
	padding: 10px !important;
	border-radius: 25px;
	display: inline-block;
	/* border: 2px solid #0eacc2; */
  background: linear-gradient(268deg, #09AFC6 6.35%, #00DAB9 90.75%);
  cursor: pointer;
}
.tableheding{
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #00799B;
  border-radius: 5px 05px 0 0;
  align-items: center;
}
.tableheding h2{
  color: #fff !important;
  font-size: 22px !important;
  margin: 0 !important;
}
.tableheding span{
  font-size: 15px;
  color: #fff;
}




/*************************************end Knowledge**************************************/

@media screen and (min-width: 1800px) {

.container{max-width: 1740px;}






}


@media (min-width: 1700px) and (max-width: 1799px) {

.container{max-width: 1560px;}
.whyModel {left: -30%;}


}

@media (min-width: 1600px) and (max-width: 1699px) {

.container{max-width: 1560px;}
.whyPnel {margin-right: 0px !important;}
.whyModel {left: -15%; width: 800px;}
.whtBtmCon{padding-left: 100px;}
.bnerfrmInr {padding: 40px;}
.frmBtn, .frmFill {margin: 15px 0 0;}


}


@media only screen and (max-width: 1599px) {
.headersec {padding: 25px 0;}
.hdrmnu li a {font-size: 18px;line-height: 25px;}
.hdrmnu li {margin: 0px 40px 0 0;}
.hfrBtn {padding: 0 35px 0 7px;height: 50px;font-size: 17px;line-height: 20px;}
.hfrBtn span {width: 45px;height: 40px;margin: 0 18px 0 0;}
/* .bannerSec {margin: 118px 0 0;} */
.frmFill label {font-size: 16px;line-height: 20px;margin: 0 0 7px;}
.bnrtxtinr h1 {font-size: 70px; line-height: 80px; margin: 0 0 15px;}
.bnrtxtinr p {font-size: 20px; line-height: 35px;}
.bnrtxtinr {max-width: 560px;}
.bnrtxtinr ul {margin: 35px 0 0;}
.bnrtxtinr li {margin: 0 13px 0 0;}
.bnrtxtinr li a {width: 45px; height: 45px;}
.bnrimg::after {height: 120px;}
.bnerfrmInr {max-width: 550px;padding: 35px;}
.bnerfrmInr h3 {font-size: 35px;line-height: 40px;padding: 0 0 15px;}
.frmFill input[type="text"], .frmFill input[type="email"], .frmFill input[type="tel"],.frmFill input[type="date"]{height: 55px; padding: 10px 20px 10px 55px;
font-size: 15px; }
.frmFill input{height: 55px; padding: 10px 20px 10px 55px;font-size: 15px; }
.frmFill {margin: 15px 0 0;}
.bnerForm {bottom: 0;}
.frmBtn button {height: 60px;font-size: 16px;line-height: 60px;}
.frmBtn button span {margin-left: 15px; font-size: 12px;}

.featureCon p {font-size: 17px; line-height: 30px;}
.featureCon h2 {font-size: 70px;line-height: 80px;margin: 0 0 20px;}
.featureCon {max-width: 500px;}
.redBtn {height: 55px;padding: 0 25px;font-size: 16px;line-height: 20px;}
.redBtn span {margin-left: 13px; font-size: 12px;}
.featureCon .redBtn {margin: 30px 0 0;}
.featureBx h3 {font-size: 25px;line-height: 30px;margin: 0 0 30px;}
.featureBx {margin: 60px 0 0;max-width: 250px;}
.featureCon figure {margin: 0 0 50px;}
.featureCon figure img{width: 110px;}
.featureBx::before {width: 100px;height: 107px;background-size: 100px !important;}
.featureLst .row > div:nth-child(1) .featureBx::before {bottom: 0%;right: -55%;}
.featureLst .row > div:nth-child(3) .featureBx::before {top: -8%; right: -55%;}
.featureLst .row > div:nth-child(2) .featureBx::before {bottom: 10%; left: -55%;}
.featureLst .row > div:nth-child(4) .featureBx::before {top: -16%; left: -55%;}
.featuresSec {padding: 80px 0 100px;}

.whySec::before {height: 80px;}
.pageHding h2 {font-size: 70px; line-height: 75px;}
.pageHding p { font-size: 17px; line-height: 30px; }
.whtBtmCon { margin: 30px 0 0;}
.whtBtmCon p {font-size: 17px; line-height: 30px;}
.whyPnel {margin: 70px 0 0; margin-right: -35px !important; max-width: 1100px;}
.whyBx { padding: 0;}
.whyBx figure {width: 150px; height: 150px; margin: 0 0 25px;}
.whyBx h3 {font-size: 20px; margin: 0 0 15px;}
.whyModel{pointer-events: none;}
.whyBx figure::before {right: -75%; width: 85px; height: 17px; background-size: 85px !important;}
.whySec {padding: 125px 0 90px;}
.whyModel {left: -27%; bottom: -90px; width: 800px;}

.aboutCon h2 {font-size: 70px; line-height: 80px; margin: 0 0 25px;}
.aboutCon p {font-size: 17px; line-height: 30px;}
.aboutCon .redBtn {margin: 30px 0 0;}
.aboutCon {max-width: 545px;}
.abt_icn {width: 700px;}
.aboutImg { max-width: 700px;}
.playVideo {width: 65px;height: 65px;font-size: 19px;}
.aboutMain {padding: 90px 0;}

.clientLgo {height: 180px; padding: 20px;}
.clientSldr .owl-nav button {width: 45px;height: 45px;font-size: 11px !important;}
.clientSldr .owl-nav {bottom: -75px;}
.clientSec { padding: 70px 0 150px;}

.fterPay h3, .foterLink h3, .foterinfo h3 {font-size: 20px; margin: 0 0 30px;}
.fterPay h3 i, .foterLink h3 i, .foterinfo h3 i {margin-right: 13px; width: 25px;}
.foterLink li {font-size: 15px;}
.fterPay p {font-size: 16px; line-height: 28px;}
.foterLink {padding-left: 75px;}
.foterLink, .foterinfo, .fterPay {margin: 26px 0 0;}
.foterSec {padding: 55px 0 50px;}
.bnrtxt {bottom: 140px;}


.bredLink h1 {font-size: 60px;line-height: 65px;margin: 0 0 15px;}
.bredLink li {font-size: 20px; line-height: 25px;}
.inerBner .bnrimg::after {height: 100px;}
.price-body li {padding-left: 25px;font-size: 16px;line-height: 23px;margin: 0 0 15px;}
.price-body li::before{width: 24px; height: 24px; background-size: 24px;}
.price-header h4 {font-size: 30px;margin: 0 0 20px;}
.price-header h5 {font-size: 16px;line-height: 20px;margin: 20px 0;}
.price-header h6 {font-size: 18px;}
.price-header {padding: 25px 0px 15px 0px;}
.price-section .row > div > div {min-height: 1185px;}

}

@media only screen and (max-width: 1399px) {

.hdrmnu li a {font-size: 16px;line-height: 20px;}
.hdrlgo {width: 270px;}
.hdrmnu li {margin: 0px 30px 0 0;}
.headersec {padding: 15px 0;}
/* .bannerSec {margin: 84px 0 0;} */
.bnrimg::after {height: 88px;}
.bnrtxtinr h1 {font-size: 55px;line-height: 60px;margin: 0 0 10px;}
.bnrtxtinr p {font-size: 17px; line-height: 30px;}
.bnrtxtinr {max-width: 460px;}
.bnrtxtinr ul {margin: 25px 0 0;}
.bnrtxtinr li {margin: 0 10px 0 0;}
.bnrtxtinr li a {width: 40px; height: 40px;}
.bnerfrmInr h3 {font-size: 30px; line-height: 35px; padding: 0 0 10px;}
.frmFill label {font-size: 15px; line-height: 20px; margin: 0 0 5px;}
.frmFill {margin: 10px 0 0;}
.frmFill input[type="text"], .frmFill input[type="email"], .frmFill input[type="tel"],.frmFill input[type="date"] {
height: 50px;padding: 8px 15px 8px 45px;font-size: 14px;}
.frmFill input{height: 50px;padding: 8px 15px 8px 45px;font-size: 14px;}
.frmFill span {left: 18px; font-size: 14px;}
.frmBtn {margin: 15px 0 0;}
.frmBtn button {height: 50px; font-size: 15px; line-height: 50px;}
.frmBtn button span { margin-left: 12px; font-size: 11px;}
.bnerfrmInr {max-width: 450px; padding: 25px;}

.aboutCon h2 {font-size: 55px;line-height: 60px;margin: 0 0 20px;}
.aboutCon p {font-size: 16px; line-height: 28px;}
.redBtn {height: 50px;padding: 0 20px;font-size: 15px;line-height: 20px;}
.aboutCon .redBtn {margin: 20px 0 0;}
.aboutCon {max-width: 445px;}
.aboutImg {max-width: 600px;}
.playVideo {width: 55px; height: 55px; font-size: 18px;}
.aboutMain {padding: 65px 0;}
.abt_icn {width: 500px;}
.pageHding h2 {font-size: 55px; line-height: 60px;}
.clientSec {padding: 45px 0 115px;}
.clientLgo {height: 150px; padding: 40px;}
.fterPay h3, .foterLink h3, .foterinfo h3 {font-size: 18px; margin: 0 0 25px;}
.foterLgo{display: inline-block; width: 250px;}
.frtsocial {margin: 30px 0 0;}
.frtsocial h3 {font-size: 18px;line-height: 25px;margin: 0 0 20px;}
.frtsocial li {margin: 0 13px 0 0;}
.frtsocial li a {width: 40px;height: 40px;}
.foterinfo p {font-size: 14px; line-height: 24px;}
.foterinfo .media > span {margin: 0 17px 0 0;width: 40px;height: 40px;}
.foterinfo b {font-size: 17px; font-weight: 500; line-height: 22px;}
.fterPay p {font-size: 15px; line-height: 26px;}
.fterPay ul {margin: 0 0 55px;}
.foterSec {padding: 40px 0 40px;}
.whySec::before {height: 55px;}
.whySec {padding: 90px 0 65px;}

.whyPnel {margin: 50px 0 0;margin-right: -30px !important;max-width: 950px;}
.pageHding p {font-size: 16px; line-height: 28px;}
.whyModel {left: -27%; bottom: -65px; width: 695px;}
.whtBtmCon {margin: 25px 0 0;}
.whtBtmCon p {font-size: 16px; line-height: 28px;}
.whyBx h3 {font-size: 18px; margin: 0 0 12px;}
.whyBx p {font-size: 14px;}
.whyBx figure { width: 135px; height: 135px; margin: 0 0 20px;}
.whyBx i {font-size: 65px; width: 65px;}
.whyBx figure::before {right: -68%; width: 75px; height: 16px; background-size: 75px !important;}

.featureCon h2 {font-size: 60px;line-height: 70px;margin: 0 0 15px;}
.featureCon p {font-size: 15px; line-height: 28px;}
.featureCon figure {margin: 0 0 30px;}
.featureCon figure img {width: 100px;}
.featureCon {max-width: 450px;}
.featureBx h3 { font-size: 20px;line-height: 25px; margin: 0 0 20px;}
.featureBx {margin: 50px 0 0; max-width: 200px;}
.featureLst {top: 20px;}
.featuresSec {padding: 75px 0 80px;}

.bredLink h1 {font-size: 50px; line-height: 55px;}
.bredLink li {font-size: 17px; line-height: 24px; padding-right: 17px; margin-right: 17px;}
.bredLink li::before {font-size: 12px;}
.inerBner .bnrimg::after {height: 80px;}

.taglnk {
	font-size: 16px !important;
}
.dshbox .card-body h6 {
	font-size: 16px;
}
.tableheding h2 {
	font-size: 18px !important;
}
.tableheding span {
	font-size: 13px;
}

}

@media only screen and (max-width: 1199px) {

.hdrmnu li a {font-size: 15px;line-height: 20px;}
.hdrmnu li {margin: 0px 20px 0 0;}
.hdrlgo {width: 215px;}
.hfrBtn {padding: 0 25px 0 7px;height: 45px;font-size: 15px;line-height: 20px;}
.hfrBtn span {width: 40px; height: 35px; margin: 0 15px 0 0;}
.headersec {padding: 11px 0;}
/* .bannerSec {margin: 67px 0 0;} */
.bnrtxtinr h1 {font-size: 45px;line-height: 53px;margin: 0 0 8px;}
.bnrtxt {bottom: 115px;}
.bnrtxtinr {max-width: 400px;}
.bnrimg > img {height: 550px;}
.whyModel{display: none;}
.whyPnel {margin: 35px 0 0;margin-right: 0px !important;max-width: 100%; float: none;}
.whySec::before {height: 35px;}
.pageHding h2 {font-size: 45px; line-height: 50px;}
.pageHding p { font-size: 15px; line-height: 27px; margin: 0;}
.aboutCon h2 {font-size: 45px;line-height: 50px;margin: 0 0 15px;}
.aboutCon {max-width: 415px;}
.aboutImg {max-width: 495px;}
.aboutMain {padding: 50px 0;}
.abt_icn {width: 450px;}
.clientSldr .owl-dots{display: none !important;}
.foterLink {padding-left: 45px;}
.foterinfo p {font-size: 13px; line-height: 23px;}
.foterinfo li {margin: 0 0 25px;}
.foterSec {padding: 35px 0;}
.fterPay p {font-size: 14px; line-height: 25px;}

.featureCon h2 {font-size: 50px;line-height: 58px;margin: 0 0 13px;}
.featureCon { max-width: 400px;}
.featureBx { margin: 35px 0 0; max-width: 180px;}
.featureLst {top: 50px;}

.headersec.fixed_header{padding: 12px 0;}

.inerBner .bnrimg > img{height: 300px;}
.price-header img {margin-bottom: 15px; width: 60px; height: 60px;}
.price-header h3 {font-size: 14px;line-height: 30px;margin-bottom: 25px;}
.price-header h6 {font-size: 16px;}
.price-header h4 {font-size: 25px; }
.price-header h5 {font-size: 15px; line-height: 20px; margin: 15px 0;}
.price-body li {padding-left: 20px; font-size: 15px; line-height: 22px; margin: 0 0 12px;}
.price-body li::before {width: 18px;height: 18px;background-size: 18px;top: 3px;}
.price-body {padding: 25px 12px 12px 12px;}
.price-section .row > div > div {min-height: 1085px;}
.price-section {padding: 50px 0px 65px;}
}


@media only screen and (max-width: 991px) {

.headersec{padding: 0px;}
.headersec .container{padding: 0px; max-width: 100%;}
.hdrlgo{margin: 12px;}
.collapse-button{right: 12px; background: #00799B !important;}
.hfrBtn{margin: 0 80px 0 0;}

.bnerForm {position: static; margin: 30px 0 0;}
.bnerfrmInr { max-width: 100%; padding: 25px; float: none;}
.bnrimg::after {height: 50px;}
.bnrtxt {bottom: auto;top: 50%;transform: translateY(-50%);}
.bnrtxtinr {max-width: 500px; margin: 0 auto; text-align: center;}
.bnrtxtinr ul { margin: 20px 0 0; justify-content: center;}
.bnrimg > img {height: 400px;}
ul.slimmenu{position: absolute; top: 100%; left: 0; width: 100%; z-index: 9; background: #FFF;box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;}
.hdrmnu li{margin: 0;}
.hdrmnu li a{padding: 10px 12px;}

.featureCon {max-width: 100%;}
.featureLst { position: static;}
.featureCon h2 {font-size: 45px; line-height: 50px; margin: 0 0 12px;}
.featureLst .row{margin: 0 -12px;}
.featureLst .row > div{padding: 0 12px;}
.featureBx {margin: 30px 0 0;max-width: 100%;text-align: center !important;}
.featureBx figure{max-width: 300px; margin: 0 auto;}
.featureBx::before{content: none;}
.featuresSec {padding: 55px 0;}

.whyBx{margin: 30px 0 0;}
.whyPnel {margin: 0;}
.whyBx figure::before{content: none;}
.whySec { padding: 55px 0;}
.whySec::before {height: 15px;}
.whtBtmCon p {font-size: 15px; line-height: 27px;}

.aboutMain {padding: 45px 0;}
.aboutPnl { flex-direction: column;}
.aboutCon { max-width: 100%; margin: 25px 0 0;}
.aboutMain::before{width: 100%;}

.foterLink {padding-left: 0;}
.foterLink, .foterinfo, .fterPay { margin: 30px 0 0;}

.headersec.fixed_header{padding: 0;}

.price-section .row > div > div {min-height: 1010px;}
.bredLink h1 {font-size: 45px;line-height: 50px;}

.fstbg{
  height: auto;
  
}
.scndbg{
  height: auto;
}

}
 
@media only screen and (max-width: 767px){

.fterPay ul {margin: 0 0 28px;}
.bnrtxtinr h1 { font-size: 35px; line-height: 45px;}
.bnrtxtinr{max-width: 100%;}
.bnrimg > img { height: 350px;}
.bnrimg::after {height: 35px;}

.bredLink h1 {font-size: 40px;line-height: 45px;}
.inerBner .bnrimg > img {height: 265px;}
.price-header h6 {font-size: 15px;}
.price-header img {margin:0 auto 13px; width: 55px; height: 55px;}


.knowledgeVideo {
  border-left: none;
}
}

@media only screen and (max-width: 575px){
.bnrtxtinr h1 { font-size: 30px; line-height: 40px;}
.foterSec .row > div.col-sm-6{width: 50%;}
.fterPay h3, .foterLink h3, .foterinfo h3{flex-direction: row-reverse; justify-content: flex-end;}
.fterPay h3 i, .foterLink h3 i, .foterinfo h3 i {margin:0 15px 0 0;}
.foterinfo li {margin: 0 0 15px;}

.price-section .row{margin: 0 -12px;}
.price-section .row > div{padding: 0 12px; border: 0px !important;}
.price-section .row > div > div{margin: 25px 0 0; border: 1px solid #DADADA; min-height: auto;}
.price-section .row > div:hover {transform: inherit;}


}

@media only screen and (max-width: 480px){ 

.bnrtxtinr h1 {font-size: 20px;line-height: 25px;}
.bnrtxtinr p {font-size: 14px;line-height: 24px;}
.bnrimg > img {height: 300px;}
.hfrBtn {padding: 0 15px;height: 35px;font-size: 14px;line-height: 20px;margin: 0 60px 0 0;}
.hfrBtn span {display: none;}
.hdrlgo {width: 160px;}
/* .bannerSec {margin: 56px 0 0;} */
.bnerfrmInr h3 { font-size: 25px; line-height: 30px;}
.bnerfrmInr {padding: 20px;}
.featureCon h2 {font-size: 40px; line-height: 45px;}
.pageHding h2 {font-size: 35px; line-height: 40px;}
.pageHding p {font-size: 14px; line-height: 24px;}
.playVideo {width: 45px; height: 45px; font-size: 16px;}
.aboutCon h2 { font-size: 35px;line-height: 40px;}
.abt_icn {width: 300px;}
.clientLgo { height: 100px; padding: 20px;}
.foterSec .row > div.col-sm-6 { width: 100%;}


.foterLink li {width: auto; float: left; margin: 0 15px 15px 0;padding-left: 16px;}
.foterLink ul {display: flex; justify-content: center; flex-wrap: wrap;}
/* .foterLink li:last-child{margin: 0;} */
.frtsocial {text-align: center;}
.fterPay{text-align: center;}
.fterPay ul{justify-content: center;}
.fterPay h3, .foterLink h3, .foterinfo h3{justify-content: center;}
.foterinfo ul{display: flex; flex-wrap: wrap; justify-content: center;}
.foterinfo li{margin: 10px 14px !important;}
}

.homecontainer{
  background-color: #e1e1e1;
}

.homecircleouter{
 background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);
 padding: 20px;
 margin: 60px 0;
 border-radius: 10px;
    box-shadow: 0px 0px 20px #ddd;
}

.formheading h1{
  color: #323232;
  padding: 20px 0px;
}

.formgroupinner input:focus{
  box-shadow: none;
}

.form-group{
  position: relative;
}

.changePass, .toggleCPass{
  position: absolute;
    right: 12px;
    top: 30%;
}

.formgroupinner input::placeholder{
  font-size: 12px;
  color: #323232;
}

.primary-submit{
    background: #00799b;
    color: #fff;
    border: 2px solid #00799b;
    padding: 6px 15px;
    border-radius: 5px;
}

.primary-submit:hover{
  color: #fff;
  border: 2px solid #00799b;
  background-color: transparent;
  transition: all 0.5s;
}

.forgot a{
  color: #fff;
}

.forgot a:hover{
    color: #00799B;
    transition: all 0.3s;
}

.userinfobx label{
  margin-bottom: 5px;
  font-size: 14px;
}

.log_container{padding: 30px 0px;}

.loginpage{
  margin-top: 100px;
}

.registerbox_left {
  height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.innerrow {
  background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);
}
.reginner h3{color: #fff;font-size: 30px;margin-bottom: 30px;}
.reginner a{border: 2px solid #fff;padding: 10px 20px;color: #fff;}
.reginner a:hover{color: #fff;background-color: #000;border: 2px solid #000;transition: all 0.5s;}
.well h2{color: #fff;}
.well{background-color: #314d61;padding: 20px;}
.well .control-label{color: #fff;margin-bottom: 10px;}
.well .btn-effect{margin-bottom: 10px; border-radius: 0px; color: #fff; background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);border: 1px solid #fff;}
.well .signup_donot p{color: #fff;}
.well .signup_donot a{color: #00f69b;}

/* .pdtop{
  padding-top: 130px;
} */

.newbred{
  position: relative !important;
  text-align: left;
}
.newbred li a {
	color: #666;
}
.newbred .container{
  border-bottom: 1px solid #ccc;
  padding: 18px 12px;
}


@media only screen and (max-width: 575px){

.bnrtxtinr h1 { font-size: 30px; line-height: 40px;}
.foterSec .row > div.col-sm-6{width: 50%;}
/* .fterPay h3, .foterLink h3, .foterinfo h3{flex-direction: row-reverse; justify-content: flex-end;}
.fterPay h3 i, .foterLink h3 i, .foterinfo h3 i {margin:0 15px 0 0;}
.foterinfo li {margin: 0 0 15px;} */
/* .pdtop {
	padding-top: 45px;
} */
}

@media only screen and (max-width: 480px){ 

.bnrtxtinr h1 {font-size: 20px;line-height: 25px;}
.bnrtxtinr p {font-size: 14px;line-height: 24px;}
.bnrimg > img {height: 300px;}
.hfrBtn {padding: 0 15px;height: 35px;font-size: 14px;line-height: 20px;margin: 0 60px 0 0;}
.hfrBtn span {display: none;}
.hdrlgo {width: 160px;}
/* .bannerSec {margin: 56px 0 0;} */
.bnerfrmInr h3 { font-size: 25px; line-height: 30px;}
.bnerfrmInr {padding: 20px;}
.featureCon h2 {font-size: 40px; line-height: 45px;}
.pageHding h2 {font-size: 35px; line-height: 40px;}
.pageHding p {font-size: 14px; line-height: 24px;}
.playVideo {width: 45px; height: 45px; font-size: 16px;}
.aboutCon h2 { font-size: 35px;line-height: 40px;}
.abt_icn {width: 300px;}
.clientLgo { height: 100px; padding: 20px;}
.foterSec .row > div.col-sm-6 { width: 100%;}


/* .foterLink li {line-height: 35px; width: auto; float: left; margin: 0 15px 0 0;padding-left: 16px;}
.foterLink ul {display: flex; justify-content: center; flex-wrap: wrap;}
.foterLink li:last-child{margin: 0;}
.frtsocial {text-align: center;}
.fterPay{text-align: center;}
.fterPay ul{justify-content: center;}
.fterPay h3, .foterLink h3, .foterinfo h3{justify-content: center;}
.foterinfo ul{display: flex; flex-wrap: wrap; justify-content: center;}
.foterinfo li{margin: 10px 14px !important;} */
}

/********************10/10/2023**************/

/* .bredLink {
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  padding: 10px 0;
}
.bredLink ul {
  display: flex;
  align-items: center;
} */
/* .bredLink li {
  position: relative;
  color: #f3364c;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.36px;
  padding-right: 23px;
  margin-right: 10px;
} */

/* .bredLink li a{color: #000;}
.bredLink li a::before {
  position: absolute;
    content: "";
    height: 16px;
    width: 1px;
    background-color: #000;
    right: 0;
    transform: rotate(20deg);
    top: 3px;
} */
@media only screen and (max-width: 480px){
  .abut-con{padding: 10px;}
 }




/*--------------------------------------------------------------
# Contact Form
--------------------------------------------------------------*/
.contact-form-box {
  position: relative;
  display: block;
  padding: 60px 0 80px;
}

.contact-form-box__title-box {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 95px;
}

.contact-form-box__title-box span {
  font-size: 18px;
  font-weight: 500;
  color: var(--corpai-base);
  opacity: .80;
}

.contact-form-box__title-box h3 {
  font-size: 43px;
  font-weight: 700;
  line-height: 50px;
  margin-top: 10px;
}

.contact-form-box__left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.contact-form-box {
  position: relative;
  display: block;
}

.contact-form-box__input-box {
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.contact-form-box__input-box p {
  font-size: 15px;
  color: #433838;
  opacity: .70;
  line-height: 20px;
}
.contact-form-box__input-box p i{
  color: red;
}

.contact-form-box__input-box input[type="text"],
.contact-form-box__input-box input[type="email"] {
  height: 50px;
  border: 0;
  width: 100%;
  font-size: 16px;
  /* color: var(--corpai-black); */
  border-bottom: 1px solid #000;
  font-weight: 500;
  outline: none;
  color: #433838;
}

.contact-form-box__input-box textarea {
  height: 100px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  color: #433838;
  opacity: .70;
  font-size: 15px;
  outline: none;
  margin-top: -8px;
}

.contact-form-box__input-box.text-message-box {
  height: 140px;
  margin-bottom: 22px;
}

.contact-form-box__btn-box {
  position: relative;
  display: block;
}

.contact-form-box__btn {
  padding: 12px 35px 11px;
  border: none;
  background: linear-gradient(268deg, #00C0DB 6.35%, #00DAB9 90.75%);
  color: #fff;
  border-radius: 4px;
}
.contact-form-box__btn:hover{
  background: linear-gradient(268deg, #01F69B 6.35%, #00DAB9 90.75%);
}

.contact-form-box__btn:after {
  background-color: #000;
}


.contact-form-box__right {
  position: relative;
  display: block;
  margin-left: 30px;
  margin-top: 4px;
}

.contact-form-box__img {
  position: relative;
  display: block;
  z-index: 1;
}

.contact-form-box__img img {
  width: 100%;
}

.contact-form-box__img-shape-1 {
  position: absolute;
  bottom: 40px;
  left: -30px;
}

.contact-form-box__img-shape-1 img {
  width: auto;
}

.contact-form-box__img-shape-2 {
  position: absolute;
  bottom: -50px;
  left: 40px;
  mix-blend-mode: overlay;
}

.contact-form-box__img-shape-2 img {
  width: auto;
}



.reservations {
  display: flex;
  align-items: center;
}
.reservations .icon {
	position: relative;
	top: 3px;
	max-width: 39px;
	width: 100%;
	text-align: center;
}
.reservations .icon span {
  font-size: 30px;
  color: #00799b;
}
.reservations .icon.color-1 span {
  font-size: 40px;
  color: #fff;
}
.reservations .text {
  margin-left: 20px;
}
.reservations .text p {
  font-size: 15px;
  margin: 0;
  
}
.reservations .text a {
  font-size: 24px;
  color: #00799b;
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 1px;
}
.reservations .text a.color-1 {
  color: #fff;
}
.mb-30 {
	margin-bottom: 30px;
}

.contact-info{
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 30px;
}


.adrsbox{
  padding-left: 30px;
}

.newacnt{
  margin-top: 15px;
}

.close-modal{
  position: absolute;
  right: -15px;
  top: -15px;
  width: 30px;
  height: 30px;
  background: #00799b;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.close-modal span{
  padding-bottom: 0px;
  font-size: 15px;
}


@media only screen and (max-width: 575px){
.adrsbox {
	padding: 20px 0;
}
.reservations .text a {
	font-size: 20px;
}
.reservations .icon span {
	font-size: 26px;
}

.price-body li:before {
  top: 0;
}
.ReactModalPortal{
  padding: 12px;
}
.ReactModalPortal iframe{
  width: 100%;
  height: auto;
}
}



